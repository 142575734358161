/* App.css complet avec intégration directe des polices modernes depuis Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&family=Raleway:wght@300;400;700&display=swap');

:root {
  --primary-color: #d32f2f;
  --secondary-color: #f44336;
  --background-gradient: linear-gradient(135deg, #121212 0%, #1e1e1e 100%);
  --overlay-color: rgba(0, 0, 0, 0.65);
  --card-bg: #1e1e1e;
  --card-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  --card-shadow-hover: 0 8px 16px rgba(0, 0, 0, 0.4);
  --font-family-base: 'Roboto', sans-serif;
  --font-family-headings: 'Raleway', sans-serif;
  --transition-speed: 0.3s;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: #d6d6d6;
}

body {
  font-family: var(--font-family-base);
  margin: 0;
  padding: 0;
  background: var(--background-gradient);
  color: #e0e0e0;
  line-height: 1.6;
  transition: background 0.5s ease;
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--font-family-headings);
  font-weight: 700;
}

hr {
  border: none;
  height: 1px;
  background: linear-gradient(to right, transparent, #6c757d, transparent);
  margin: 1.5rem auto;
  width: 75%;
}

.text-center {
  text-align: center;
}

/* Bouton primaire avec effet hover */
.btn-primary {
  background: linear-gradient(45deg, var(--primary-color), var(--secondary-color));
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  color: #fff;
  cursor: pointer;
  transition: background var(--transition-speed), transform var(--transition-speed);
  font-family: var(--font-family-base);
}

.btn-primary:hover {
  background: linear-gradient(45deg, var(--secondary-color), var(--primary-color));
  transform: translateY(-2px);
}

/* Cards */
.card {
  background-color: var(--card-bg);
  color: #e0e0e0;
  border-radius: 10px;
  box-shadow: var(--card-shadow);
  transition: transform var(--transition-speed), box-shadow var(--transition-speed);
  overflow: hidden;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: var(--card-shadow-hover);
}

/* Navbar */
.navbar {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.navbar-brand img {
  height: 50px;
  transition: transform var(--transition-speed);
}

.navbar-brand img:hover {
  transform: scale(1.1);
}

.nav-link:hover {
  color: var(--secondary-color);
}

/* Footer */
.footer {
  background-color: #343a40;
  text-align: center;
  padding: 20px;
  color: #e0e0e0;
  border-top: 1px solid #444;
  font-size: 0.9rem;
}

.footer .hover-underline:hover {
  text-decoration: underline;
}

/* Formulaires et listes */
.form-control {
  background-color: var(--card-bg);
  color: #e0e0e0;
  border: 1px solid #343a40;
  border-radius: 5px;
  padding: 10px;
  transition: border var(--transition-speed), box-shadow var(--transition-speed);
  font-family: var(--font-family-base);
}

.form-control:focus {
  border-color: var(--primary-color);
  box-shadow: 0 0 5px rgba(211, 47, 47, 0.5);
  outline: none;
}

.list-group-item {
  background-color: var(--card-bg);
  color: #e0e0e0;
  border: 1px solid #343a40;
  transition: background-color var(--transition-speed);
  font-family: var(--font-family-base);
}

.list-group-item:hover {
  background-color: #2a2a2a;
}

/* Décorateurs de sections */
.section-decorator,
.contact-decorator svg {
  width: 100%;
  height: auto;
  display: block;
}

/* Modales */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1040; /* Un z-index inférieur à celui du modal */
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in {
  animation: fadeIn 1.5s ease-in;
}

/* ---------- Section Hero ---------- */
.hero-section {
  position: relative;
  overflow: hidden;
  /* Au lieu d'une hauteur en vh, on utilise un ratio 21:9 pour une section plus large et moins haute */
  aspect-ratio: 21 / 9;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  left: 0;
}

/* Overlay en dégradé noir -> transparent */
.overlay-animated {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent);
  z-index: 1;
  opacity: 0;
  animation: fadeOverlay 1.5s ease-in-out forwards;
}

/* Contenu de la Hero */
.hero-content {
  position: relative;
  z-index: 2;
  max-width: 800px;
  text-align: center;
  margin: auto;
}

.hero-text-effect {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
  letter-spacing: 1px;
}

@keyframes fadeOverlay {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* ---------- Responsiveness ---------- */
@media (max-width: 992px) {
  .hero-content h1 {
    font-size: 2.5rem;
  }
}

@media (max-width: 768px) {
  .hero-content h1 {
    font-size: 1.8rem;
  }
  .hero-content p {
    font-size: 1.05rem;
  }
}

@media (max-width: 576px) {
  .hero-content h1 {
    font-size: 1.6rem;
  }
  .hero-content p {
    font-size: 0.95rem;
  }
}
